define("discourse/plugins/discourse-user-network-vis/discourse/initializers/user-network-vis-inits", ["exports", "discourse/lib/plugin-api", "discourse-common/resolver"], function (_exports, _pluginApi, _resolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'user-network-vis-inits',
    initialize(container) {
      const currentUser = container.lookup("current-user:main");
      const siteSettings = container.lookup("site-settings:main");
      const isMobileDevice = (0, _resolver.getResolverOption)("mobileView");
      if (!siteSettings.user_network_vis_enabled || !currentUser || isMobileDevice) return;
      (0, _pluginApi.withPluginApi)('0.8.13', api => {
        api.decorateWidget("hamburger-menu:generalLinks", function (helper) {
          return {
            href: "/usernetworkvis",
            rawLabel: I18n.t('user_network_vis.hamburger_menu_label')
          };
        });
      });
    }
  };
});