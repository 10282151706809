define("discourse/plugins/discourse-user-network-vis/discourse/components/usernetworkvis", ["exports", "discourse/lib/load-script", "discourse/lib/url", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _loadScript, _url, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = Ember.Component.extend((_dec = (0, _decorators.observes)("hasItems"), (_obj = {
    classNames: "user-network-vis",
    results: Ember.computed.alias("model.results"),
    hasItems: (0, _computed.notEmpty)("results"),
    ensureD3() {
      return (0, _loadScript.default)("/plugins/discourse-user-network-vis/d3/d3.min.js");
    },
    didInsertElement() {
      if (!this.site.mobileView) {
        this.waitForData();
      }
    },
    waitForData() {
      if (!this.hasItems) {
        return;
      } else {
        this.setup();
      }
    },
    setup() {
      var _this = this;
      this.ensureD3().then(() => {
        function fade(opacity) {
          return function (d) {
            // check all other nodes to see if they're connected
            // to this one. if so, keep the opacity at 1, otherwise
            // fade
            node.style("opacity", function (o) {
              return isConnected(d.currentTarget.__data__, o) ? 1 : opacity;
            });
            // also style link accordingly
            link.style("opacity", function (o) {
              return o.source.id === d.currentTarget.__data__.id || o.target.id === d.currentTarget.__data__.id ? 1 : opacity;
            });
          };
        }
        var width = 1120,
          height = _this.siteSettings.user_network_vis_canvas_height;
        var svg = d3.select(".user-network-vis").append("svg").attr("width", width).attr("height", height);
        var color = d3.scaleOrdinal(_this.siteSettings.user_network_vis_colors.split("|"));
        var simulation = d3.forceSimulation().force("link", d3.forceLink().id(d => {
          return d.id;
        })).force("charge", d3.forceManyBody().strength(-_this.siteSettings.user_network_vis_node_charge_strength)).force("center", d3.forceCenter(width / 2, height / 2));
        var link = svg.append("g").attr("class", "links").selectAll("line").data(_this.results.user_network_stats.links).enter().append("line").attr("stroke-width", d => {
          return Math.cbrt(Math.round(d.value) + 1);
        });
        var node = svg.append("g").attr("class", "nodes").selectAll("g").data(_this.results.user_network_stats.nodes).enter().append("g").on("mouseover", fade(.1)).on("mouseout", fade(1));
        var circles = node.append("circle").attr("r", _this.siteSettings.user_network_vis_node_radius).attr("fill", d => {
          return color(d.group + 1);
        }).call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended)).on("click", (event, d) => {
          if (d.id) {
            _url.default.routeTo(`/u/${d.id}/summary`);
          }
        });
        var labels = node.append("text").text(d => {
          return d.id;
        }).attr("x", _this.siteSettings.user_network_vis_node_radius + 1).attr("y", _this.siteSettings.user_network_vis_node_radius / 2 + 1);
        node.append("title").text(d => {
          return d.id;
        });
        simulation.nodes(_this.results.user_network_stats.nodes).on("tick", ticked);
        simulation.force("link").links(_this.results.user_network_stats.links);
        var linkedByIndex = {};
        simulation.force("link").links().forEach(function (d) {
          linkedByIndex[d.source.index + "," + d.target.index] = 1;
        });
        function isConnected(a, b) {
          return linkedByIndex[a.index + "," + b.index] || linkedByIndex[b.index + "," + a.index] || a.index == b.index;
        }
        function ticked() {
          link.attr("x1", d => {
            return d.source.x;
          }).attr("y1", d => {
            return d.source.y;
          }).attr("x2", d => {
            return d.target.x;
          }).attr("y2", d => {
            return d.target.y;
          });
          node.attr("transform", d => {
            return "translate(" + d.x + "," + d.y + ")";
          });
        }
        function dragstarted(event, d) {
          if (!event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        }
        function dragged(event, d) {
          d.fx = event.x;
          d.fy = event.y;
        }
        function dragended(event, d) {
          if (!event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "waitForData", [_dec], Object.getOwnPropertyDescriptor(_obj, "waitForData"), _obj)), _obj)));
});